.element-container {
    padding: 2%;
    margin-bottom: 2%;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
    border: 1px solid #e6e6e6;
    border-image: initial;
    border-radius: 11px;
  }
  
  /* element 41 */
  .design-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: 20px;
    column-gap: 4px;
    margin-top: 4% !important;
  }
  
  .design-item img {
    transition: transform 0.2s ease-in-out;
  }
  
  .design-item:hover {
    transform: scale(1.05);
  }
  
  .design-item img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .design-header {
    margin-bottom: 1rem;
  }
  
  .design-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 34.7px;
    color: #212121BF;
    margin-left: 5%;
  }
  
  .design-view {
    color: #484964;
    font-weight: 700;
    font-size: 20px;
    line-height: 34.7px;
    margin-right: 5%;
  }
  
  .design-view img {
    width: 12px;
    height: 12px;
    margin: 4px;
  }
  
  /* element-40 */
  .section-heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 34.75px;
    margin-bottom: 1.5rem;
    color: #212121BF;
  }
  
  .aari-designs {
    display: flex;
    gap: 30px;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .parent-card,
  .sub-card,
  .child-card {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #f0f8ff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .parent-card {
    width: 160px;
    height: 160px;
    flex-direction: column;
    justify-content: center;
  }
  
  .sub-card,
  .child-card {
    height: 80px;
    width: 20%;
    margin-bottom: 2%;
  
  }
  
  .parent-card img {
    margin-bottom: 5px;
    width: 60px;
    height: 60px;
  }
  
  .sub-card img {
    width: 60px;
    height: 60px;
  }
  
  .child-card img {
    width: 40px;
    height: 40px;
  }
  
  .parent-card p,
  .sub-card p,
  .child-card p {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    color: #212121;
    margin: 0;
  }
  
  .sub-card p,
  .child-card p {
    margin-left: 10%;
    text-wrap: nowrap;
  }
  
  /* element-34 & element-35 */
  .size-table {
    margin-top: 20px;
    background-color: #f7fbfc;
    border-color: #eef5fa;
  }
  
  .size-table th {
    font-size: 16px;
    background-color: #0072B91A !important;
    font-weight: 500;
    color: #007bff !important;
    border: 2px solid #0072B933 !important;
  }
  
  .table-item {
    background-color: #0072B91A !important;
    font-size: 18px;
    line-height: 26.06px;
    font-weight: 500;
    color: #212121 !important;
    border: 2px solid #0072B933 !important;
    padding-left: 1.5% !important;
    position: relative;
  }
  
  .table-rowinfo {
    font-size: 16px !important;
    line-height: 23.17px !important;
    font-weight: 500 !important;
    color: #595959 !important;
    text-align: center !important;
    border: 2px solid #0072B933 !important;
  }
  
  td img {
    position: absolute;
    right: 16px;
    top: 50%;
  }
  
  /* element-36 & element-37 */
  .upload-btn {
    width: 85%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0072B933 !important;
    border-color: #0072B933 !important;
    color: #595959 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20.27px;
    border-radius: 4px !important;
  }
  
  .upload-btn img {
    width: 16px;
    margin-right: 4px;
    margin-bottom: 4px;
    height: 16px;
  }
  
  .form-label {
    font-weight: 500;
    font-size: 16px;
    color: #595959 !important;
    margin-bottom: 1.3rem !important;
  }
  
  .form-control {
    height: 50px;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 23.17px;
    color: #212121 !important;
    border: 1px solid #DBDCF5 !important;
  }
  
  .form-control:focus {
    box-shadow: 0 0 0 1px rgba(13, 110, 253, .25) !important;
  }
  
  .preferred-materials .form-label {
    margin-bottom: .8rem !important;
  }
  
  .preferred-materials {
    background-color: #0072B90F !important;
    padding: 5% 7% 7% !important;
    width: 90%;
  }
  
  .preferred-material {
    background-color: #0072B90F !important;
    padding: 2% 3% 3% !important;
    width: 35%;
  }
  
  /* element-38 */
  .preview-btn {
    width: 10%;
    height: 48px;
    border-radius: 6px !important;
    background-color: #0072B9 !important;
  }
  
  .preview-btn img {
    margin-right: 12px;
    margin-left: 3px;
  }
  
  /* element-33 */
  .add-btn {
    background-color: #B1B2B7 !important;
    width: 36px;
    height: 36px;
    border-radius: 4px;
  }
  
  .more-btn {
    background-color: #B1B2B7 !important;
    width: 120px;
    height: 36px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
  }
  
  /* element 39 */
  .css-1wy0on6 {
    display: none !important;
  }
  
  .css-cdxiwt {
    padding-top: 0px !important;
  }
  
  .css-1ryl8vy-multiValue {
    padding: 0px 2px !important;
    align-items: center;
  }
  
  .row-bottom {
    margin-bottom: 20px;
  }
  
  .design-btn {
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0072B933 !important;
    border-color: #0072B933 !important;
  }
  
  .design-btn img {
    margin-bottom: 4px;
  }
  
  .minus-btn {
    background-color: #48496426 !important;
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
  
  /* element 42 & element 45 */
  .header-bar {
    background-color: #D9D9D933;
    box-shadow: 0px 1px 5px 0px #00000040;
    padding: 10px 20px;
  }
  
  .menu-btn {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .logo {
    margin-right: 10px;
  }
  
  .btn-container {
    border-right: 2px solid #3d42c014;
    border-left: 2px solid #3d42c014;
    padding: 0px 10px;
    margin-left: 15px;
  }
  
  .btn1-container {
    padding: 0px 10px;
    margin-left: 15px;
  }
  
  .icon-btn {
    border: none;
    background: none;
  }
  
  .icon-btn img {
    width: 22px;
    height: 22px;
    margin-bottom: 4px;
  }
  
  .blouse-text {
    font-weight: bold;
    color: #6c757d;
    margin-right: 10px;
  }
  
  .save-btn {
    background-color: #007bff;
    font-size: 16px !important;
    width: 150px;
    font-weight: 400 !important;
    margin-right: 10px;
    border: none;
  }
  
  .publish-btn {
    background-color: #28a745;
    width: 150px;
    font-weight: 400 !important;
    font-size: 16px !important;
    border: none;
  }
  
  /* element 46 */
  .menu-container {
    display: flex;
    flex-direction: column;
    width: 90px;
    background-color: #D9D9D933;
    border-right: 2px solid #e9ecef;
    padding-top: 10px;
    margin-left: 2%;
  }
  
  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    color: #6c757d;
    cursor: pointer;
    border-bottom: 1px solid #4849640F;
    border-top: 1px solid #4849640F;
    margin-bottom: 8px;
    height: 120px;
  }
  
  .menu-item img {
    width: 30px;
  }
  
  .menu-item span {
    font-size: 14px;
    font-weight: 500;
  }
  
  .menu-item.active {
    background-color: #0072B9;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
  }
  
  .menu-item.active img {
    color: #fff;
  }
  
  /* element 47 */
  .tab-menu {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .tab-menu button {
    padding: 4px 30px;
    height: 34px;
    background-color: #D9D9D933;
    box-shadow: 1px 1px 5px 0px #00000040 !important;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid #00000026;
  }
  
  .tab-menu .active-tab {
    background-color: #007bff;
    border: none;
    color: white;
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 15px;
    height: 500px;
    overflow-y: auto;
    padding-right: 15px;
  }
  
  .design-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }
  
  /* element 48 */
  .sleeve-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    column-gap: 15px;
    row-gap: 25px;
    padding: 20px;
    width: 40%;
    height: 300px;
    overflow-y: auto;
    padding-right: 15px;
  }
  
  .sleeve-button {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    border: 1px solid #48496433;
    background-color: #F0F3F6;
    border-radius: 6px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    box-shadow: 1px 1px 1px 0px #00000033;
  }
  
  .sleeve-button span {
    text-wrap: nowrap;
    font-size: 16px;
    font-weight: 400;
    color: #212121;
    margin-left: 10px;
  }
  
  .sleeve-button.active {
    border-color: #007bff;
  }
  
  .sleeve-icon {
    width: 40px;
    height: 40px;
  }
  
  .sleeve-button:hover {
    border-color: #007bff;
  }
  
  /* element 49 */
  .neck-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 25px;
    padding: 20px;
    width: 40%;
    height: 300px;
    overflow-y: auto;
    padding-right: 15px;
  }
  
  .neck-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px 24px;
    border: 1px solid #0000001A;
    background-color: #F0F3F6;
    border-radius: 6px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    box-shadow: 1px 1px 1px 0px #48496433;
  }
  
  .neck-button span {
    text-wrap: nowrap;
    font-size: 16px;
    font-weight: 500;
    color: #212121;
    line-height: 20.27px;
    margin-top: 10px;
  }
  
  .neck-button.active {
    border-color: #007bff;
  }
  
  .neck-icon {
    width: 60px;
    height: 60px;
  }
  
  .neck-button:hover {
    border-color: #007bff;
  }
  
  /* element 51 */
  .material-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    column-gap: 15px;
    row-gap: 25px;
    padding: 20px;
    width: 40%;
    height: 300px;
    overflow-y: auto;
    padding-right: 15px;
  }
  
  .material-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    border: 1px solid #48496433;
    background-color: #F0F3F6;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    box-shadow: 1px 1px 1px 0px #00000033;
  }
  
  .material-button span {
    text-wrap: nowrap;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }
  
  .material-button.active {
    border-color: #0072B9;
    background-color: #0072B9;
  }
  
  .material-button.active span {
    color: #FFFFFF;
  }
  
  .material-button:hover {
    border-color: #007bff;
  }
  
  /* element 54 */
  .client-list-container {
    width: 330px;
    background-color: #D9D9D959;
    border-radius: 8px;
    padding: 16px 0px 40px 23px;
  }
  
  .client-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 24px;
  }
  
  .client-list-header h2 {
    font-size: 14px;
    margin: 0;
    color: #000000;
    font-weight: 500;
  }
  
  .badge {
    border-radius: 50% !important;
    margin-left: 4px;
    font-size: 10px;
    font-weight: 500;
  }
  
  .add-client-button {
    font-size: 16px;
    border: none;
    cursor: pointer;
    background-color: transparent;
  }
  
  .client-list {
    max-height: 400px;
    overflow-y: auto;
    padding-right: 16px;
  }
  
  .client-name {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
  }
  
  .client-card {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border: 1px solid #48496433;
    box-shadow: 1px 1px 1px 0px #00000024;
    border-radius: 8px;
    padding: 8px 12px 14px;
    margin-bottom: 10px;
  }
  
  .client-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .client-details {
    padding: 4px 14px 0px;
    display: flex;
  }
  
  .client-details div {
    display: flex;
    flex-direction: column;
  }
  
  .client-details p {
    margin: 0;
    font-size: 10px;
    font-weight: 400;
    color: #484964;
  }
  
  /* element 55 */
  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .pagination-btn {
    border: 1px solid #0072B933;
    background-color: white;
    color: #0072B9;
    font-size: 10px;
    padding: 10px 15px;
    cursor: pointer;
    outline: none;
  }
  
  .pagination-btn:hover:not(.active) {
    background-color: #e9f0f7;
  }
  
  .pagination-btn.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  /* element 60 */
  .settings {
    width: 250px;
    background-color: #0072B9;
    padding: 20px 0px;
    color: white;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
  }
  
  .settings ul {
    list-style-type: none;
    padding: 0;
  }
  
  .settings li {
    padding: 15px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5%;
    display: flex;
    align-items: center;
  }
  
  .settings li img {
    width: 19px;
    height: 19px;
    margin-right: 16px;
  }
  
  .settings li.active {
    background-color: #D9D9D9;
    color: #0072B9;
    border-radius: 5px;
  }
  /* element 63 */
  .content {
    width: 80%;
    padding: 10px;
    background-color: #F6FAFD;
  }
  
  .content h2 {
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 500;
  }
  
  .content p {
    margin-bottom: 10px;
    line-height: 23.17px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
  }
  
  .stats {
    display: flex;
    justify-content: flex-start;
    margin-top: 45px;
    flex-wrap: wrap;
  }
  
  .stat-card {
    background-color: transparent;
    box-shadow: 1px 1px 2px 0px #0000000F;
    border: 1px solid #0072B92E;
    padding: 12px 20px;
    border-radius: 5px;
    width: 360px;
    text-align: center;
    display: flex;
    margin-right: 8%;
    margin-bottom: 6%;
  }
  
  .stat-card .icon {
    font-size: 40px;
    color: #007bff;
  }
  
  .stat-card .info {
    width: 100%;
    text-align: left;
    padding-left: 8%;
  }
  
  .stat-card .info h3 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #484964;
  }
  
  .stat-card .info p {
    font-size: 28px;
    font-weight: 500;
    color: #484964;
  }
  /* element 61 */
  .profile-card {
    background-color: #FFFFFF;
    display: flex;
  }
  
  .profile-card .info {
    margin-left: 1%;
  }
  
  .profile-card .info p:first-child {
    font-size: 18px;
    font-weight: 500;
    color: #444444;
  }
  
  .profile-card .info p:nth-child(2) {
    font-size: 14px;
    font-weight: 500;
    color: #595959;
  }
  
  .profile-card p {
    margin-bottom: 0px;
  }
  
  .profile-card .icon img {
    width: 48px;
    height: 48px;
  }
  /* element 59 */
  .feedback-card {
    background: #D9D9D933;
    border: 1px solid #0000001A;
    padding: 12px 20px;
    border-radius: 5px;
    width: 340px;
  }
  
  .feedback-card .icon {
    margin-bottom: 18px;
    padding-left: 5px;
  }
  
  .feedback-card .info p:first-child {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 10px;
  }
  
  .feedback-card .info p:nth-child(2) {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 0px;
    margin-left: 2px;
  }
  /* element 62 */
  .input-wrapper {
    height: 40px !important;
  }
  
  .input-wrapper::placeholder {
    color: #00000066 !important;
  }
  
  .input-label {
    margin-bottom: 10px !important;
  }
  /* element 56 */
  .reviews-table-container {
    padding: 20px;
    width: 100%;
  }
  
  .reviews-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .reviews-table th, .reviews-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .reviews-table th {
    background-color: #D9D9D940;
    color: #484964;
    font-size: 16px;
    font-weight: 500;
  }
  
  .reviews-table td {
    vertical-align: top;
  }
  
  .rating-text {
    font-weight: 500;
    margin-bottom: 2px;
    font-size: 16px;
    color: #484964;
  }
  
  .rating-stars .star {
    font-size: 18px;
    color: #ffc107;
  }
  
  .rating-stars .star.half {
    color: #ffc107;
  }
  
  .rating-stars .star.empty {
    color: #ddd;
  }
  
  .rating-comment{
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 20.27px;
  }
  