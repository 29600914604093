.tablecon {
    padding: 30px;
    padding-right: 50px;
  }
  .cardbutton p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    margin-left: 10px;
    margin-bottom: 1px;
  }
  .cardbutton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: #0072b9;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }



  .cardbuttonopen p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    margin-left: 10px;
    margin-bottom: 1px;
  }
  .cardbuttonopen{
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    background-color: #0072b9;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  .first {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    width: 101%;
    padding-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 34.75px;
    text-align: left;
  }
  .icon {
    margin-top: 10px;
    height: 48px;
    width: 40px;
  }
  .name {
    margin-top: 15px;
  }
  
  
  .tables {
    padding: 30px;
    margin-top: -40px;
  }
  .table-border {
    border: 1px solid #E7EAEE;
    border-radius: 10px;
    border-collapse: collapse;
  }
  .table-head {
    background: #e0edfa !important;
    text-align: left;
  }
  .table-head-one {
    text-align: center;
    background: #e0edfa !important;
    padding: 18px, 24px, 18px, 24px;
  }
  .table-left {
    background: #f4f5f6 !important;
    text-align: left;
  }
  .table-left-back{
    text-align: left;
    text-wrap: nowrap;
    background: #f4f5f6 !important;
  }
  th {
    height: 57px;
    width: 223px;
    vertical-align: middle;
  }
  td {
  
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-align: center;
    padding: 18px, 24px, 18px, 24px;
    height: 57px;
    width: 223px;
    vertical-align: middle;
  }

  /* .modal {
    display: block !important;
    position: fixed;
    top: 0;
    right: 0 !important;
    left: auto !important;
    width: 79.5%  !important;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    transform: translateX(0);
    transition: transform 0.3s ease;
    overflow: auto;
  } */
  

  .modal-content {
    padding: 20px !important;
    border: none !important;
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
  }
  
  /* .modal-close {
    background: #595959;
  
    color: #fff;
    border: none;
    font-size: 10px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 2px;
    height: 36px;
    width: 34px;
    z-index: 9999;
    margin-left: -4%;
    margin-top: -25px;
    position: absolute;
  } */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5) !important;
    justify-content: flex-end;
   }