/* Main Wrapper */

.new-container-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Main Section */
  
  .new-main-content {
    padding: 20px;
    background-color: #f7f9fc;
  }
  
  /* Header Section */
  
  .new-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: #ffffff;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
  }
  
  .new-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .new-create-new-btn {
    background-color: #0072b9;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .new-create-new-btn:hover {
    background-color: #0056b3;
  }
  
  .new-plus-icon {
    margin-right: 10px;
  }
  
  /* Measurement Items */
  
  .new-size-input-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .new-size-input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  /* Remove Button */
  
  .new-remove-size-btn {
    border: none;
    background: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .new-remove-size-btn img {
    width: 24px;
    height: 24px;
  }
  
  /* Add More Button */
  
  .new-add-size-btn {
    background-color: #b1b2b7;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
    width: 30%;
    text-align: center;
  }
  
  .new-add-size-btn:hover {
    background-color: #343a40;
  }
  
  /* Responsive Design */
  
  @media (max-width: 768px) {
    .new-header-container {
      flex-direction: column;
      align-items: flex-start;
    }
    .new-create-new-btn {
      margin-top: 10px;
      width: 100%;
      justify-content: center;
    }
    .new-size-input-wrapper {
      flex-direction: column;
      align-items: stretch;
    }
    .new-size-input {
      margin-right: 0;
      margin-bottom: 10px;
    }
    .new-remove-size-btn {
      align-self: flex-end;
    }
  }
  