.label{
padding-bottom: 10px;
font-size: 16px;
font-weight: 400;
color: #616161;
line-height: 23.17px;
}
/* .main {
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f7f9;
    color: #12344d;
    display: flex;
} */

.container{
border-radius: 10px;
box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
border: 1px solid #e6e6e6;
border-image: initial;
border-radius: 11px;
}
.loginform{
    padding: 4% 4%;
  }
  .btn.disabled, .btn:disabled, fieldset:disabled .btn {
    background-color: #DBDCF5 !important;
    border: none !important;
    border-radius: 8px !important;
    font-size: 18px !important;
    color: #ffffff !important;
    font-weight: 500px !important;
  }
  .subbutton:active {
    background-color: #0072B9 !important;
  }

  /* //cleint card// */
  .clientcard {
    background-color: #ffffff; /* Light background color */
    border-radius: 10px;
    padding: 28px;
    display: flex;
    margin-bottom: 5%;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px; /* Adjust this based on your design */
  }
  
  .card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .client-info {
    display: flex;
    flex-direction: column;
  }
  
  .client-info h3 {
    font-size: 18px;
    margin: 0;
    color: #333;
  }
  
  .client-count {
    font-size: 36px;
    margin: 0;
    color: #000;
  }
  
  .client-image img {
    max-width: 80px; /* Adjust this based on your design */
  }

  /* //categery image button // */
  .cardcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  
  }
  
  .cardimage {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
  }
  
  .cardtext {
    color: #4d5c7c;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .cardbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    background-color: #0072B9;
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .cardbutton:hover {
    background-color: #0072B9;
  }
  
  .cardicon {
    margin-right: 8px;
    font-size: 20px;
    font-weight: bold;
  }

/* Modal container */
.modal {
  display: block !important;
  position: fixed;
  top: 0;
  right: 0 !important;
  left: auto !important;
  width: 700px  !important;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transform: translateX(0);
  transition: transform 0.3s ease;
}

/* Modal content */
.modal-content {
  padding: 20px !important;
  border: none !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Close button */
.modal-close {
  background-color: #2f3644;
  color: #fff;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  position: absolute;
  left: 798px;
  z-index: 99999;
  top: 3%;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: start !important;
  padding: 10px 20px;
  background-color: #ffffff; /* Light grey background for header */
  border-bottom: 1px solid #ddd; /* Border line under header */
}


/* Header */
h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Form group styling */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.form-group .form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* File upload styling */
.file-upload {
  border: 2px dashed #d3dce6;
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: #f9f9f9;
}

.file-upload input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-upload .upload-icon {
  margin-bottom: 15px;
}


.file-upload p {
  font-size: 16px;
  color: #2c3a4a;
  display: flex;
  justify-content: center;

}

.file-upload #file-link {
  color: #1a73e8;
  text-decoration: none;
  font-weight: 600;
}

.file-upload #file-link:hover {
  text-decoration: underline;
}

.file-info {
  font-size: 14px;
  color: #6b7c93;
  margin-top: 10px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Buttons */
.save-button {
  background-color: #0072B9;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-publish-button {
  background-color: #15C573;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover, .save-publish-button:hover {
  opacity: 0.8;
}
.formalign{
  padding: 2% 10%;

}
.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center !important;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5) !important;
  justify-content: flex-end; /* Aligns the modal to the right */
}


/* Feedback Container Styles */

.feedback-container {
  width: 100%;
  max-width: 400px;
  border-radius: 15px;
  background-color: #ecf6f8;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #e0e0e0;
  overflow: hidden;
  position: relative;
}

.feedback-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #444;
  padding: 5px 0;
  font-weight: bold;
}

.view-all {
  color: #888;
  font-size: 16px;
  /* Adjusted size */
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  /* Adds space between text and the symbol */
  cursor: pointer;
}

.view-all-symbol {
  font-size: 20px;
  margin-left: 5px;
  color: #888;
}

.feedback-content {
  min-height: 500px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px;
  border: 1px solid #e0e0e0;
  overflow: auto;
}

/* //categery header /// */

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #f7f9fc !important;
  border-bottom: 1px solid #e0e0e0;
}

.category-title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.category-count {
  font-size: 24px;
  color: #000000;
}

.create-new-btn {
  background-color: #0072B9;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.create-new-btn:hover {
  background-color: #0056b3;
}

/* //parent categery // */

.parentheader {
  background-color: #d0c8f4;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.header h2 {
  margin: 0;
  font-size: 20px;
  color: #333;
}

.search-filter {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

.search-bar input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  font-size: 14px;
}

.search-icon {
  font-size: 18px;
  color: #999;
  margin-left: 10px;
}

.filter-icon {
  font-size: 18px;
  color: #999;
  cursor: pointer;
}

.category-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin-bottom: 10px;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-contentone {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;

}


.card-image-placeholder {
  width: 50px;
  height: 50px;
  background-color: #ccc;
  border-radius: 5px;
  margin-right: 15px;
}

.card-info {
  flex-grow: 1;
  padding-right: 15px;
}

.card-info h3 {
  margin: 0 0 5px;
  font-size: 18px;
  color: #333;
}

.card-info p {
  margin: 0;
  font-size: 14px;
  color: #777;
}

.card-actions {
  display: flex;
  align-items: center;
}

.view-btn {
  width: 100%;
  background-color: #0072B9;
  border-radius: 20px !important;
  color: white;
  border: none;
  padding: 3px 20px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
}
.view-btnone{
  width: 100%;
  background-color: #15C573;
  border-radius: 20px !important;
  color: white;
  border: none;
  padding: 3px 20px;
  font-size: 14px;
  cursor: pointer;
  /* margin-right: 10px; */
}

.view-btn:hover {
  background-color: #0056b3;
}

.status-label {
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.dots-menu {
  font-size: 18px;
  color: #999;
  cursor: pointer;
}

/* //save btn // */
.save-btn {
  background-color: #0072B9;
  border-radius: 20px !important;
  color: white;
  border: none;
  padding: 5px 25px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
}
.saveimg{
  margin-top: -4px;
  margin-left: 5px;
  width: 16px;

}


/* //w card // */

.wcard {
  background-color: #ffffff;
  border-radius: 20px;
  width: 200px;
  padding: 20px;
  text-align: center;
  border: 1px solid #48496433;
}

.icon-circle {
  background-color: #d7e6f3;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.icon-circle img {
  width: 60px;
  height: 60px;
}

.category-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
/* //chosse subcategory ///  */

.subheader {
  display: flex;
  align-items: center;
  background-color: #e5f1f9;
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;
}

.category-title {
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 500;
  color: #000000;
  gap: 15px;
    align-items: baseline;
    display: flex;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  background: #ffffff;
  margin-left: 2%;
}

.back-button, .close-button {
  background: none;
  border: none;
  font-size: 18px;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.subcategory-title {
  font-size: 14px;
  color: #007bff;
}

/* //master parent child card // */
.parentcard {
  border: 1px solid #48496433;
  background-color: #ffffff;
  border-radius: 15px;
  width: 140px;
  text-align: center;
  /* height: 120px; */
  padding: 10px;
}
.subcard {
  border-radius: 11px;
  width: 200px;
  border: 1px solid #48496433;
  background-color: #ffffff;
}
.childcard {
  background-color: #eef5fa;
  border-radius: 11px;
  width: 200px;
  padding: 7px;
  text-align: center;

}
/* //categery tittle step// */
.category-titlestep{
margin-bottom: 0px;
color: #7171e4;
font-size: 16px;
    font-weight: bold;
    color: #000;
}
/* // select progreess bar // */

.procontainer {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

.prolabels {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.prolabel {
  font-size: 18px;
  font-weight: 500;
}

.progress-container {
  width: 100%;
  margin-top: 10px;
}

.progress {
  background-color: #2FEA9B !important;
  border-radius: 10px;
  height: 10px !important;
  width: 50%;
  position: relative;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: #7FDD53 !important;
  border-radius: 10px;
}

/* // front back // */

.upload-container {
  padding: 20px;
  background-color: #f7f9fc;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

h3 {
  font-size: 18px !important;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.file-upload {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f7f9fc;
}

.file-box {
  border: 2px dashed #91c7ff;
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  background-color: #fff;
}

.file-box p {
  margin: 0;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.upload-area {
  margin-top: 10px;
  cursor: pointer;
}

.cloud-icon {
  font-size: 36px;
  color: #3792e5;
}

.browse-link {
  color: #3792e5;
  text-decoration: underline;
  cursor: pointer;
}

.format-info {
  font-size: 12px;
  color: #999;
  margin-top: 8px;
}

input[type="file"] {
  display: none;
}

/* //design image upload // */

.designupload-container {
  padding: 20px;
  max-width: 300px;
  margin: 0 auto;
}

.designlabel {
  font-size: 16px;
  font-weight: 600;
  color: #555;
  margin-bottom: 10px;
}

.designupload-box {
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  width: 95%;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  background-color: #ffffff;
  display: flex;
}

.designupload-box:hover {
  border-color: #3792e5;
}

.designupload-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.designupload-icon {
  font-size: 24px;
  color: #3792e5;
}

.designupload-label span {
  color: #48496499;
  font-size: 16px;
  font-weight: 600;
}

input[type="file"] {
  display: none;
}
/* //design card // */
.designcard{
  background-color: #ffffff;
  border-radius: 11px;
  width: 100px;
  padding: 5px;
  text-align: center;
}
.displayflex{
  display: flex;
  gap: 10px;

}

/* //svg pattern // */

.pattern-container {
  padding: 20px;
  background-color: #f8f9fa; /* Light grey background */
  border-radius: 8px;
  max-width: 400px;
}

.pattern-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.pattern-item {
  display: flex;
  gap: 10px;
}

.pattern-image {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background: #ffffff;

}
.flexcenter{
  display: flex;
  justify-content: center;
}
.patternmargin{
  margin-top: 10% !important;
  margin-bottom: 10% !important;

}

/* //toggele 3 button // */
.toggle-buttons {
  display: flex;
  border-radius: 50px;
  overflow: hidden;
  width: 85%;
  background-color: #e0e0e0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.toggle-button {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: #e0e0e0; 
  color: #888888; 
}

.toggle-button.active {
  background-color: #2d73b7;
  color: white;
}

/* // duplicate button // */
.dupicatebutton{
  display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    background-color: #ffffff;
    color: #484964;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}
/* //save continue // */
.savecontinuebutton{
  display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    background-color: #ffffff;
    color: #484964;
    border-radius: 5px;
    border: 1px solid #bcbcf9 !important;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

/* //image eye toggle// */
#imageContainer {
  background: #ffff;
  text-align: center;
}
/* ././ */
.error-message{
  color: red;
}
.success-message{
  color: green;
}

.flexnowrap {
  display: flex !important;
  flex-wrap: nowrap !important;
  width: 102%;
  overflow-y: auto; /* Change from hidden to auto */
  height: 80%;
  scrollbar-width: none; /* Hides the scrollbar for Firefox */
  -ms-overflow-style: none; /* Hides the scrollbar for Internet Explorer and Edge */
}

.flexnowrap::-webkit-scrollbar {
  display: none; /* Hides the scrollbar for Chrome, Safari and Opera */
}

.dots-menu {
  cursor: pointer;
  font-size: 24px;
  color: #333;
  padding: 0;
  margin-left: auto;
}

.dots-menu:hover {
  color: #000;
}

/* Add this CSS to your stylesheet to override the Bootstrap dropdown arrow */
.dropdown-toggle::after {
  content: '\0022EE' !important;  
  font-size: 20px !important;    
  vertical-align: 0; 
  border: none;   
  border-top: 0px !important;
    border-right: 0px !important;
    color: #000000 !important;
    font-size: 24px !important;    
}
.spacearound{
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
}
.spacearoundms{
  display: flex;
  justify-content: space-around;
}
.masterheader{
    background-color: #BFC5F8;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 20px;
}
.subheader1{
  background-color: #92C5F3;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.childheader{
  background-color: #92C5F3;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.scroll {
  height: 70%;
  overflow: auto;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #cccccc #f0f0f0; /* Firefox */
  background-color: #f9f9f9;
  /* padding: 10px;/ */
  /* border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.scroll::-webkit-scrollbar {
  width: 10px;
}

.scroll::-webkit-scrollbar-track {
  background: #f0f0f0; 
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #00aaff; /* Scrollbar thumb color */
  border-radius: 10px;
  border: 2px solid #f0f0f0; /* Adds padding between track and thumb */
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color: #0088cc; /* Darker color on hover */
}

.imgcss{
  width: 50px;
  height: 50px;
}
.mcdiv{
  height: 100vh !important;
  /* overflow-x: unset !important;
  overflow-y: clip !important; */
}
.measurementuploadimage{
  border: 2px solid #e0e0e0;
    border-radius: 8px;
    padding: 4px;
}
.designupload-contentone{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;

}
.unique-add-more-button{
  background: #B1B2B7 !important;
  border: none !important;
}


.popover {
  position: absolute !important;
 
  width: 200px !important;
  background-color: #f9f9f9 !important; 
  border: 1px solid #d3d3d3 !important;
  border-radius: 5px !important;
  padding: 10px !important;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2) !important;
  z-index: 1 !important;  /* Ensures it's on top */
}

.card-info .p{
  font-size: 14px;
  margin: inherit !important;
}
.measurementflex{
  display: flex;
  gap: 5%;
  align-items: end;
  margin-top: 3% !important;
  margin-bottom: 3% !important;

}
.image-width-70{
  width: 70px;
}
.image-width-71{
  width: 70px;
  padding: 10px;
}
.measurementsave{
  padding: 1% 4%;
  margin: 2% 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  background-color: #ffffff;
  color: #484964;
  border-radius: 5px;
  border: 1px solid #bcbcf9 !important;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}
.active {
  background-color: black;
}

.paginationmeascontainer {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px;
  border: 1px solid #ebe7e7;
}

.items-per-page {
  display: flex;
  align-items: center;
    width: 22%;
}

.items-per-page label,
.items-per-page select,
.items-per-page span {
  margin-right: 8px;
}

.page-navigation span {
  margin-right: 8px;
}

button {
  background-color: #eee;
  border: 1px solid #ddd;
  padding: 5px 10px;
  cursor: pointer;
}


.widthcls{
  width: 25% !important;
}

.page-navigation{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.searchdiv{
  display: flex;
    align-items: center;
    width: 20%;
    float: right;
    position: absolute;
    right: 0;
}
.messearchbar{
  display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
    width: 120%;
}
.messearchbar input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  font-size: 14px;
}
.imgmes{
  width: 76px;
  height: 76px;
}
.measurementcard{
  background: #0072B905;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin-bottom: 10px;
  text-align: center;
  border: 1px solid #0000001A;
}
.card-contentmes{
  display: flex;
  justify-content: end;
  padding: 10px;
}
.mes-viewbtn{
  width: 55%;
    background-color: #0072B9;
    border-radius: 20px !important;
    color: white;
    border: none;
    padding: 3px 20px;
    font-size: 14px;
    cursor: pointer;
}
.mes-viewbtnone{
  width: 54%;
  background-color: #15C573;
  border-radius: 20px !important;
  color: white;
  border: none;
  padding: 3px 20px;
  font-size: 14px;
  cursor: pointer;
}
.mespara{
  text-transform: capitalize;
    line-height: 20.86px;
    font-size: 15px !important;
}
.meshead{
  text-transform: capitalize;
  line-height: 31.86px;
  font-size: 20px !important;
}
.active-card {
  border: 2px solid black !important;
}

.active-category {
  border: 2px solid #000;
  background-color: #f0f0f0;
}

.sample{
  float: inline-end;
  margin-bottom: 2%;
  background: linear-gradient(to right, #80a7d1 0%, #a8c2df 100%);
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.atag{
  text-decoration: none;
    color: #ffffff;
}
.search-barchild{
  display: flex;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 10px;
    width: 48%;
}

.search-barchild input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  font-size: 14px;
}
.cardbuttonchild{
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  /* padding: 10px 20px; */
  background-color: #0072b9;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.save-buttonchildcancel {
  width: 40%;
  background-color:#B1B2B742;
  color:#000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-buttonchild {
  width: 40%;
  background-color: #0072B9;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.card-contentbtn{
  display: flex;
  justify-content: end;
}
.masterh3{
  margin: 10px 0 5px !important;
    font-size: 18px;
    color: #333;
}
.card-contentmesment{
  display: flex;
  align-items: flex-start;
    justify-content: space-between;
 padding: 5px;
}

@media(max-width:767px) {
  .category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px !important;
    background: #f7f9fc !important;
    border-bottom: 1px solid #e0e0e0;
}
.category-title {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  gap: 5px !important;
  align-items: baseline;
  display: flex;
}
.category-count {
  font-size: 19px !important;
  color: #000000;
}
.create-new-btn {
  padding: 5px 10px !important;
  background-color: #0072B9;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.flexnowrap {
  display: flex !important;
  flex-wrap: nowrap !important;
  width: 143% !important;
  overflow-y: auto;
  height: 80%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.paginationmeascontainer {
  display: block !important;
  padding: 10px;
  border: 1px solid #ebe7e7;
}
.items-per-page {
  display: flex;
  align-items: center;
  width: auto !important;
}
.searchdiv {
  display: flex !important;
  align-items: center;
  width: 85%;
  float: none !important;
  position: unset !important;
  margin-top: 6% !important;
}
.sibebarcontent {
  height: 63% !important;
  overflow: auto;
}
.sidebar-menu {
  padding: 0px 15px 10px 15px !important;
}


}
.mesdupicatebutton{
  display: flex;
    align-items: baseline;
    justify-content: center;
    padding-top: 0;
    border: 1px solid #0000001A;
    background-color: #f3f6fb;
    color: #484964;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}
.card-actionsmes{
  display: flex;
  align-items: center;
  gap: 12px;
}
.active-border {
  border: 2px solid black;  /* Border applied when the input has a value */
}
