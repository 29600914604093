.bgcol{
  background-color: #2c3192;
  height: 100vh;
}
.forgotdiv{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.forgot-password {
  text-decoration: underline;
}
.overflow{
  overflow: hidden !important;
}
.alignitemcenter{
  display: flex;
  justify-content: center;
  align-items: center;
}
.paddingleft{
  padding-left: 10%;
  padding-right: 10%;
}
.subbutton{
  background-color: #DBDCF5 !important;
    border: none !important;
    border-radius: 8px !important;
    font-size: 18px !important;
    color: #ffffff !important;
    transition: background-color 0.3s, color 0.3s !important;
    display: inline-block !important;

}
.subbutton:active {
  background-color: #3e8e41 !important;
}
.imgbg{
  width: 100%;
  /* padding: 5%; */
  margin-top: -13%;
  /* width: 101.5%; */
  height: 900px;
}
.imgcol{
  padding: 0% !important;
}
.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
  font-size: 15px;
  margin-top: -37%;
}

.footer-logo {
  width: 80px; /* Adjust size as needed */
  margin-bottom: 10px;
}

.rights-text, .developer-text {
  margin: 0;
}
.powertext{
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}