.unique-dashboard-wrapper {
    display: flex;
    min-height: 100vh;
  }
  
  .unique-main-section {
    flex-grow: 1;
    padding: 20px !important;
    background-color: #f2f3f7;
  }
  
  /* Header Section */
  
  .unique-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: #ffffff;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .unique-page-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .unique-create-button {
    background-color: #0072b9;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .unique-create-button:hover {
    background-color: #0056b3;
  }
  
  .unique-icon-plus {
    margin-right: 10px;
  }
  
  /* Measurement Item */
  
  .unique-measurement-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .unique-input-field {
    flex-grow: 1;
    margin-right: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  /* Remove Button */
  
  .unique-remove-size-btn {
    border: none;
    background: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .unique-remove-size-btn img {
    width: 40px;
    height: 30px;
  }
  
  /* Upload Button */
  
  .unique-upload-btn {
    background-color: #0072b933;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    border-radius: 5px;
  }
  
  .unique-upload-btn img {
    margin-right: 5px;
  }
  
  .unique-upload-btn:hover {
    background-color: #0056b355;
  }
  
  /* Add More Button */
  
  .unique-add-more-button {
    background-color: #b1b2b7;
    color: white;
    border: none;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    display: inline-block;
    margin-top: 10px;
  }
  
  .unique-add-more-button:hover {
    background-color: #909299;
  }
  
  /* Pagination Section */
  
  .unique-pagination-section {
    display: flex;
    justify-content: flex-start;
    /* Align the button to the left */
    margin-top: 20px;
    gap: 30px;

  }
  
  .unique-next-button {
    background-color: white;
    color: #0072b9;
    border: 1px solid #0072b980;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .unique-next-button:hover {
    background-color: #0072b9;
    color: white;
    border: 1px solid transparent;
  }
  
  .meslabelimgclose{
    position: absolute;
    top: -7px;
    right: -6px;
    cursor: pointer;
    font-size: 12px;
    background-color: white;
    border-radius: 50%;
    padding: 1px 4px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
  }
  .meslabimg{
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .space{
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
  }

  .paddingtop{
    padding: 2%;
  }
  .childlabelcard{
    border: 1px solid #48496433 !important;
    background-color: #eef5fa;
    border-radius: 11px;
    width: 180px;
    padding: 7px;
    text-align: center;
  }
  .spacecenter{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }